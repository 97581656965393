<template>
  <div>
    <RequestAmera></RequestAmera>
  </div>
</template>

<script>
import RequestAmera from '@core/components/FormsRegister/RequestAmera'

export default {
  components: {
    RequestAmera,
  },
  data() {
    return {}
  },
}
</script>

<style>

</style>
