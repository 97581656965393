<template>
    <div>

        <div style="display: grid; place-items: center" v-if="paymentMethodId == 0">
            <h2 class="text-center font-large-1 text-danger">
                Add Payment Method
            </h2>
            <p>Do you want to add a payment method?</p>

                <b-button variant="primary" class="ml-1" @click="setSaveInfo">
                    <span class="d-none d-sm-inline">Add payment method</span>
                    <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                </b-button>

            
        </div>

        <div class="d-flex flex-wrap mt-0 mb-0" v-if="saveData === false">
            <b-button v-if="paymentMethodId != 0" :variant="currentPaymentMethodId === 0 ? 'success' : 'primary'"
                @click="setEditInfo">
                <span class="d-none d-sm-inline">Edit </span>
                <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
            </b-button>


            <b-button class="ml-2" v-if="isDefault === 0 && paymentMethodId != 0"
                :variant="inhabilitar === true ? 'warning' : 'outline-secondary'"
                @click="updateDefaultPaymentMethod(paymentMethodId, caId)">
                <span class="d-none d-sm-inline">Set as default</span>
                <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
            </b-button>

            <b-button class="ml-2" v-if="paymentMethodId != 0 && this.proPaymentType == CREDICTCARD && isDefault === 0"
                :variant="inhabilitar === true ? 'danger' : 'outline-secondary'"
                @click="deletePaymentMethodInfo(paymentMethodId, caId)">
                <span class="d-none d-sm-inline">Delete</span>
                <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
            </b-button>

            <b-button v-if="inhabilitar === false" :variant="inhabilitar === true ? 'primary' : 'primary'" class="ml-1"
                @click="saveChanges">
                <span class="d-none d-sm-inline">Save</span>
                <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
            </b-button>
        </div>

        <!-- Show data -->
        <div v-if="proPaymentType">
            <div
                v-if="showData === true && (this.paymentMethods.brand !== '' || cargando == true) && proPaymentType == 'cc'">
                <!-- Header: Personal Info -->
                <div class="d-flex mt-1">

                    <h4 class="mb-0 ml-50" v-if="cargando === true">
                        Retrieving Information. Please wait...
                    </h4>
                </div>

                <b-form class="mt-1">
                    <b-row>
                        <b-col cols="12" md="6" lg="4">
                            <b-form-group label="Name on Credit Card">
                                <b-form-input v-model="paymentMethods.name" disabled />
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="2">
                            <b-form-group label="Card Number">
                                <b-form-input v-model="'********' + paymentMethods.last4" disabled />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="6" lg="2">
                            <b-form-group label="Expiration date">
                                <b-form-input v-model="paymentMethods.exp_month + ' / ' + paymentMethods.exp_year"
                                    disabled />
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" md="1" lg="2">
                            <b-form-group label="Defatult payment">
                                <b-form-input :value=getIsDefault(isDefault) disabled />
                            </b-form-group>
                        </b-col>


                    </b-row>
                </b-form>
            </div>
        </div>

        <!-- Add new payment -->
        <div v-if="proPaymentType && saveData === false">
            <template v-if="proPaymentType === INVOICELATER">
                <b-row>
                    <b-col md="6">
                        <b-form-group label="Invoice Later Notes">
                            <b-form-input v-model="invoiceLaterNote" disabled />
                        </b-form-group>
                    </b-col>
                </b-row>
            </template>
        </div>



        <!-- Edit payment -->
        <!-- <template v-if="aggPayment === true"> -->

        <template v-if="saveData === true">
            <b-form class="mt-1">
                <b-row v-if="proPaymentType == 'cc'">
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group label="Name on Credit Card">
                            <b-form-input v-model="paymentMethods.name_on_cc" @keypress="isText" maxlength="30" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="3">
                        <b-form-group label="Card Number">
                            <b-form-input v-model="paymentMethods.cc_number" @keypress="isNumber($event)"
                                maxlength="16" />
                        </b-form-group>
                    </b-col>


                    <b-col cols="12" md="6" lg="3">
                        <b-form-group label="Code of cc">
                            <b-form-input v-model="paymentMethods.code_of_cc" @keypress="isNumber($event)" maxlength="3"
                                placeholder="***" />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" md="6" lg="2">
                        <b-form-group label="Expiration">
                            <b-form-input v-model="paymentMethods.exp" v-mask="'##/####'" hint="MM/YYYY"
                                placeholder="MM/YYYY" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row v-if='proPaymentType == INVOICELATER'>
                    <b-col md="6">
                        <b-form-group label="Invoice Later Notes">
                            <b-form-input v-model="invoiceLaterNote" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="mt-2">
                        <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                            @click="SavePaymentMethod(proStripeCustomerId)">
                            Save Changes
                        </b-button>
                        <b-button  variant="danger"
                            :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="setShowInfo">
                            Cancel
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
    </div>
</template>

<script>
import {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BFormSelect,
    BCard
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { getMessageError } from '@core/utils/utils'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        BInputGroup,
        BInputGroupPrepend,
        BFormSelect
    },
    computed: {
        ...mapGetters({
            userData: 'Users/usersData'
        }),
    },
    props: {
        infoPayment: {},
        paymentMethodId: 0,
        isDefault: 0,
        proCaId: null,
        proStripeCustomerId: null,
        proPaymentType: null,
        proInvoiceLaterNote: null,
    },
    data() {
        return {
            currentPaymentMethodId: 0,
            reset: false,
            paymentMethods:
            {
                name_on_cc: "",
                cc_number: "",
                brand: "",
                code_of_cc: "",
                exp: "",
                exp_month: "00",
                exp_year: "0000",
                last4: "",

            },
            cargando: true,
            inhabilitar: true,
            showData: true,
            saveData: false,
            newData: false,
            option: [
                {
                    text: "Credit Card",
                    value: "cc"
                },
                {
                    text: "Invoice Later",
                    value: "il"
                }
            ],
            caId: null,
            invoiceLaterNote: null,
            CREDICTCARD: 'cc',
            INVOICELATER: 'il'
        }
    },
    methods: {
        setShowInfo() {
            this.showData = true;
            this.saveData = false;
            this.newData = false;
        },
        setEditInfo() {
            this.showData = false;
            this.saveData = true;
            this.newData = false;
        },
        setSaveInfo() {
            this.showData = false;
            this.saveData = true;
            this.newData = true;
        },
        setNewInfo() {
            this.showData = false;
            this.saveData = false;
            this.newData = true;
        },
        saveChanges() {
            this.formSubmitted()
        },
        setVariables(data) {
            this.cargando = false
            if (data.brand !== '') {
                this.setShowInfo();
            } else {
                this.setNewInfo();
            }
        },
        SavePaymentMethod(stripeCustomerId) {
            this.inhabilitar = true

            let url = `ca/panel/corporate/${this.caId}/${stripeCustomerId}/UpdateStripePaymentMethod`

            //Get month and year expiration
            if (this.proPaymentType == 'cc') {
                let expirationTimeData = this.paymentMethods.exp.split("/");
                this.paymentMethods.exp_month = expirationTimeData[0]
                this.paymentMethods.exp_year = expirationTimeData[1]
            }

            this.$swal({
                title: 'Please, wait...',
                didOpen: () => {
                    this.$swal.showLoading()
                },
            })
            if (this.paymentMethodId === 0) {
                url = `ca/panel/corporate/${stripeCustomerId}/${this.caId}/AddStripePaymentMethod`
            }

            this.paymentMethods['paymentType'] = this.proPaymentType;
            this.paymentMethods['invoiceLaterNote'] = this.invoiceLaterNote;

            this.$http.post(url, this.paymentMethods)
                .then((res) => {
                    if (res.data.status === 200) {
                        if (this.proPaymentType == 'cc') {
                            let addInfo = this.currentPaymentMethodId === 0 ? true : false;

                            this.currentPaymentMethodId = res.data.data.stripe_payment_method_id;

                            if (addInfo == true) {
                                this.$emit("updatePaymentMethod", this.currentPaymentMethodId);
                                this.$emit("addEmptyPaymentMethod");
                            }

                            this.getCard(this.currentPaymentMethodId, this.caId);
                        }

                        this.$swal({
                            title: "Record modified successfully",
                            icon: 'success',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                        this.setShowInfo()
                    } else {
                        this.$swal({
                            title: res.data.message,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
                .catch((res) => {
                    this.$swal({
                        title: getMessageError(res),
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
        },
        isText: function (event) {
            let regex = new RegExp('^[a-zA-Z ]+$')
            let key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
            if (!regex.test(key)) {
                event.preventDefault()
                return false
            }
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event
            let charCode = evt.which ? evt.which : evt.keyCode
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault()
            } else {
                return true
            }
        },
        clearInputs() {
            this.paymentMethods.name_on_cc = ''
            this.paymentMethods.cc_number = ''
            this.paymentMethods.type_of_cc = ''
            this.paymentMethods.code_of_cc = ''
        },
        getCard(paymentMethodId, caId) {
            if (paymentMethodId < 1) {
                this.setVariables(paymentMethodId);
                return false;
            }

            this.$http.get(`admin/panel/ca/${caId}/${paymentMethodId}/get_ca_credit_card_by_caid_and_payment_id`)
                .then((response) => {
                    if (response.data.data) {
                        this.paymentMethods = response.data.data;
                    }

                    this.setVariables(this.paymentMethods)
                }).catch((error) => {
                    console.log(error)
                })
        },
        getIsDefault(data) {
            let res = "";
            if (data === 1) {
                res = "Yes";
            } else {
                res = "No"
            }
            return res;
        },
        updateDefaultPaymentMethod(paymentId, caId) {
            this.$emit("updateDefaultPaymentMethod",
            {
                paymentId: paymentId,
                caId: caId
            });
        },
        deletePaymentMethodInfo(paymentId, caId) {
            this.$swal({
                title: "Please, wait...",
                didOpen: () => {
                    this.$swal.showLoading();
                },
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
            });

            this.$http
                .post(`ca/panel/corporate/${caId}/${paymentId}/DeleteStripePaymentMethod`)
                .then((response) => {
                    this.$emit("deletePaymentMethod", paymentId);
                    this.$swal().close();

                }).catch((error) => {
                    this.$swal({
                        title: getMessageError(error),
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                })
        },
        cancelAddMethod() {
            this.saveData = false;
        }
    },
    mounted() {
    },
    beforeMount() {
        this.currentPaymentMethodId = this.paymentMethodId;

        if (this.proCaId == null) {
            if (this.$store.getters['Users/userData'].user.role !== 3) {
                this.caId = this.$route.params.id;
            } else {
                this.caId = this.$store.getters['Users/userData'].user.corporate_account.id;
            }
        } else {
            this.caId = this.proCaId;
        }

        console.log( this.caId)

        this.invoiceLaterNote = this.proInvoiceLaterNote;

        this.getCard(this.currentPaymentMethodId, this.caId);

    },
}
</script>

<style></style>